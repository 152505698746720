<template>
  <div>
    <div class="header">
      <logo :src="logoImg" style="float: left;" />
      <div class="h-titles">
        <div class="h-title"><span style="margin-right: 10px">|</span>创新驱动发展</div>
        <div class="h-title"><span style="margin-right: 10px">|</span>共赢美好未来</div>
      </div>
      <div class="header-dropdwon">
        <b-dropdown size="sm" right no-caret variant="outline-secondary">
          <template #button-content>
            <b-icon icon="menu-button-wide-fill" />
          </template>
          <b-dropdown-item v-for="btn in buttonList" :key="btn.id" href="javascript:;" @click="goNext(btn.link)">{{ btn.name }}</b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="header-btn-group">
        <b-button v-for="btn in buttonList" :key="btn.id" variant="secondary" class="header-btn" @click="goNext(btn.link)">{{ btn.name }}</b-button>
      </div>
    </div>
    <router-view />
    <div class="footer">
      <div class="f-links">
        <b-link v-for="btn in buttonList" :key="btn.id" class="f-link" href="javascript:;" @click="goNext(btn.link)">{{ btn.name }}</b-link>
      </div>
      <div class="f-beians">
        <b-link class="f-beian" href="https://beian.miit.gov.cn/" target="_blank">蜀ICP备2023012900号</b-link>
        <b-link class="f-beian" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=51019002005629" target="_blank">川公网安备51019002005629号</b-link>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo'
import logoImg from '@/assets/logo/logo.png'

export default {
  components: { Logo },
  data() {
    return {
      logoImg,
      buttonList: [
        {
          id: 1,
          name: '首页',
          link: '/home'
        },
        {
          id: 5,
          name: '关于我们',
          link: '/link'
        }
      ]
    }
  },
  methods: {
    goNext: function(link) {
      this.$router.push({ path: link })
    }
  }
}
</script>

<style scoped>
.h-titles {
  float: left;
  margin: 20px 20px;
  color: #5AACDD;
  font-size: 16px;
  font-weight: 700;
}

@media (max-width: 420px) {
  .h-titles {
    font-size: 12px;
    margin: 12px 20px;
  }
}

</style>
