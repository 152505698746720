<template>
  <img :src="src" class="logo">
</template>

<script>
export default {
  name: 'Logo',
  props: {
    src: {
      type: String
    }
  }
}
</script>
