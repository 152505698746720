<template>
  <div>
    <banner :data="bannerData" />
    <div class="link-us fill-line d-table">
      <div class="d-tr">
        <div class="d-td">公司地址</div><div class="d-td">{{ linkUs.address }}</div>
      </div>
      <div class="d-tr">
        <div class="d-td">联系电话</div><div class="d-td">{{ linkUs.telephone }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import imgPc1 from '../assets/images/pc-1.jpg'
import imgPhone1 from '../assets/images/phone-1.jpg'

export default {
  // name: 'WtLink',
  components: {
    Banner
  },
  data() {
    return {
      bannerData: {
        list: [
          {
            id: 1,
            text: '立足新起点 开创新局面',
            imgPc: imgPc1,
            imgPhone: imgPhone1
          }
        ]
      },
      linkUs: {
        telephone: '028-85259636',
        address: '中国（四川）自由贸易试验区成都高新区天府二街368号2栋1单元7层7号'
      }
    }
  }
}
</script>

<style scoped>
.link-us {
  margin: 80px auto 180px auto;
  max-width: 700px;
}

.d-table .d-tr .d-td:first-child {
  width: 100px;
}

.d-tr + .d-tr {
  padding-top: 40px;
}

@media (max-width: 420px) {
  .link-us {
    margin: 80px 50px 180px 50px;
  }
}

</style>
