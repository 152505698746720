<template>
  <b-carousel
    style="text-shadow: 0xp 0px 2px #000"
    no-animation
    indicators
    :interval="4000"
    :controls="controls"
    img-width="100"
    background="#ababab"
    :img-height="imgHeight"
    label-next=""
    label-prev=""
  >
    <b-carousel-slide
      v-for="(slide) in data.list"
      :key="slide.id"
    >
      <template #default>
        <div class="carousel-slide-text vivify popIn">{{ slide.text }}</div>
      </template>
      <template #img>
        <img :src="showPc ? slide.imgPc : slide.imgPhone" class="slide-img" :height="imgHeight">
      </template>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>

export default {
  name: 'Banner',
  props: {
    data: {
      type: Object
    }
  },
  computed: {
    showPc() {
      return window.innerWidth > 420
    },
    imgHeight() {
      return window.innerWidth > 420 ? 500 : 300
    },
    controls() {
      return this.data && this.data.length > 1
    }
  }
}
</script>

<style scoped>
.carousel-slide-text {
  font-size: 36px;
  margin-bottom: 100px;
}

.slide-img {
  object-fit: cover;
}

@media (max-width: 420px) {
  .carousel-slide-text {
    font-size: 24px;
    margin-bottom: 45px;
  }
}

</style>
