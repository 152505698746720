import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/layout/index'
import Home from '@/view/Home'
import Link from '@/view/Link'

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: 'home',
    children: [
      {
        path: 'home',
        component: Home
      },
      {
        path: 'link',
        component: Link
      }
    ]
  }
]

export default new Router({
  mode: 'hash',
  routes
})
