<template>
  <div>
    <banner :data="bannerData" />
    <div class="cards fill-line">
      <div v-for="(card, index) in cardList" :key="index" :class="`delay-${card.delay}`" class="card vivify popIn">
        <div class="mark">{{ card.name }}</div>
        <!-- <logo :type="card.logo"></logo>
        <logo class="mobile" :type="card.mlogo"></logo> -->
        <h3>{{ card.c3 }}</h3>
        <h4>{{ card.c4 }}</h4>
      </div>
    </div>
    <div class="we">
      <div class="text-content">{{ we }}</div>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/Banner'
import imgPc1 from '../assets/images/pc-1.jpg'
import imgPhone1 from '../assets/images/phone-1.jpg'

export default {
  components: {
    Banner
  },
  data() {
    return {
      bannerData: {
        list: [
          {
            id: 1,
            text: '立足新起点 开创新局面',
            imgPc: imgPc1,
            imgPhone: imgPhone1
          }
        ]
      },
      cardList: [
        {
          name: '创新',
          logo: 'Logo1',
          mlogo: 'mLogo1',
          c3: '自主创新',
          c4: '公司自主研发的智能测控验证平台、AI智能辅助计算解决方案',
          delay: 0
        },
        {
          name: '融合',
          logo: 'Logo2',
          mlogo: 'mLogo2',
          c3: '技术优势',
          c4: '技术核心团队以高新技术研发人才为支撑打造拥有自主知识产权，完全自主可控的面向智能测控行业的体系产品',
          delay: 200
        },
        {
          name: '目标',
          logo: 'Logo3',
          mlogo: 'mLogo3',
          c3: '行业定位',
          c4: '公司解决方案广泛用于测控系统及工业控制系统',
          delay: 400
        }
      ],
      we: '成都维测通信息技术有限公司成立于2012年，位于成都市高新区。公司研发人员占比超过70%，研发团队中研究生以上学历人员占比超过30%，致力于专用测控设备的研发和配套服务，是一个集科研、生产、销售和服务于一体的的民营高新技术企业。'
    }
  }
}
</script>

<style scoped>
.cards {
  margin: 20px auto 0 auto;
  list-style: none;
  max-width: 1065px;
}

.cards .card {
  float: left;
  margin: 17px;
  width: 320px;
  color: #5AACDD;
  text-align: center;
  display: block;
  padding: 60px 0 40px 0;
  border: 1px solid #5AACDD;
  position: relative;
  height: 200px;
}

.cards .card:hover {
  background-color: #5AACDD;
  color: #FFFFFF;
}

.cards .card .mark {
  top: 0;
  left: 0;
  opacity: 0.1;
  font-size: 70px;
  line-height: 70px;
  position: absolute;
  background-color: #FFFFFF;
}

.cards .card:hover .mark {
  background-color:#5AACDD;
}

.cards .card h3 {
  font-size: 24px;
  margin-top: 15px;
  font-weight: 700;
}

.cards .card h4 {
  font-size: 14px;
  font-weight: 700;
  margin: 0 20px;
}

.we {
  margin: 20px auto 0 auto;
  list-style: none;
  max-width: 700px;
}

.we .text-content {
  font-size: 18px;
}

@media (max-width: 420px) {
  .cards .card {
    float: none;
    width: 270px;
    margin: 25px auto;
    padding: 32px 20px;
  }

  .we {
    margin: 25px 50px 0 50px;
    list-style: none;
  }
}

</style>
